<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Change Password</h5>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <div class="form-group" :class="{'has-danger': password && !validateOldPassword()}">
          <base-input
            type="password"
            label="Senha Antiga"
            v-model.trim="password"
            placeholder="******"
            @change="passwordChanged=true"
            >
          </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <div class="form-group" :class="{'has-danger': newPass && !validateNewPassword()}">
          <base-input
            type="password"
            label="Nova Senha"
            v-model.trim="newPass"
            placeholder="******"
            @change="newPassChanged=true"
          >
          </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <div class="form-group" :class="{'has-danger': newPassConfirmChanged && !validateCheckPassword()}">
          <base-input
            type="password"
            label="Confirmação da Nova Senha"
            v-model.trim="newPassConfirm"
            placeholder="******"
            @change="newPassConfirmChanged=true"
          >
          </base-input>
        </div>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="changePassword()">Salvar</base-button>
  </card>
</template>
<script>
export default {
  data() {
      return {
        passwordChanged: false,
        newPassChanged: false,
        newPassConfirmChanged: false,
        password: '',
        newPass: '',
        newPassConfirm: '',
        diffPass: false,
    }
  },
  methods: {
    validateOldPassword() {
        return this.password != ''
    },
    validateNewPassword() {
      return this.newPass != ''
    },
    validateCheckPassword() {
      return this.newPassConfirm != ''
    },
    validatePasswords() {
      this.diffPass = this.validateNewPassword() && this.validateCheckPassword() && this.newPass == this.newPassConfirm
      return this.diffPass
    },
    changePassword() {
      let user = this.getUsuario()
      if (user && user.login) {
        if(this.validatePasswords()) {
          var data = {
            "username" : user.login, 
            "password": this.password,
            "new_password": this.newPass
          }
          this.$http
          .post(this.$API+'user/register', data)
          .then(response => {
            if (response.data.statusCode == 200) {
              this.$swal("Sucesso", "Senha atualizada com sucesso!", "success");
            } else if (response.data.statusCode == 403) {
              this.$swal("Permissão negada", "Não foi possível atualizar a senha.", "error");
            } else {
              this.$swal("Ops!", "Ocorreu algum erro inesperado.", "error");
            }
          })
          .catch(function (error) {
                console.error(error)
          });   
        } else {
          this.$swal("Ops!", "As novas senhas informadas não conferem.", "error");
        }
      } 
    },
    getUsuario() {
      try {
        let user = JSON.parse(localStorage.AUTH_USER)
        return user
      } catch (error) {
        console.error(error)
        localStorage.AUTH_USER = null
      }
    }
  }
};
</script>
<style></style>

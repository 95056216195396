<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Login</h5>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <base-input
          label="Login"
          placeholder="Login"
          v-model="model.username"
        >
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <base-input
          type="password"
          label="Senha"
          v-model="model.password"
          placeholder="******"
        >
        </base-input>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="login()">Login</base-button>
  </card>
</template>
<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    AUTH_USER: 'authuser_dashboard'
  },
  methods: {
    login() {
      // let user = {
      //   'id': 12345,
      //   'login': 'teste',
      //   'nome': 'Fulano de Teste e Tal',
      //   'password': 'teste'
      // }

      var data = { "username" : this.model.username, "password": this.model.password }
      this.$http
      .post(this.$API+'user/login', data)
      .then(response => {
        if (response.data.statusCode == 200) {
          var resp = JSON.parse(JSON.stringify(response.data))
          localStorage.AUTH_USER = resp.body
          this.$router.push('dashboard/mensal')
        } else if (response.data.statusCode == 403) {
          this.$swal("Permissão negada", "Não foi possível realizar o login.", "error")
        } else {
          console.error(reponse.data)
          this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.data.statusCode +")", "error");
        }
      })
      .catch(function (error) {
            console.error(error)
      });   
    },
    getUsuario() {
      try {
        let user = JSON.parse(localStorage.AUTH_USER)
        return user
      } catch (error) {
        console.error(error)
        localStorage.AUTH_USER = null
      }
    }
  }
};
</script>
<style></style>

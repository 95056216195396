<template>
  <div class="row">
    <div class="col-md-12">
      <change-password-form :model="model">
      </change-password-form>
    </div>
    <!-- <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div> -->
  </div>
</template>
<script>
  import ChangePasswordForm from './Register/ChangePasswordForm';
  // import UserCard from './Profile/UserCard'
  export default {
    components: { 
      ChangePasswordForm,
      // UserCard
    },
    data() {
      return {
        model: {
          company: '',
          email: '',
          username: '',
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          country: '',
          about: ''
        },
        user: {
          fullName: '',
          title: '',
          description: '',
        }
      }
    }
  }
</script>
<style>
</style>

<template>
  <div class="row">
    <div class="col-md-12">
      <login-form :model="model">
      </login-form>
    </div>
    <!-- <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div> -->
  </div>
</template>
<script>
  import LoginForm from './Login/LoginForm';
  // import UserCard from './Profile/UserCard'
  export default {
    components: {
      LoginForm,
      // UserCard
    },
    data() {
      return {
        model: {
          company: '',
          email: '',
          username: '',
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          country: '',
          about: ''
        },
        user: {
          fullName: '',
          title: '',
          description: '',
        }
      }
    }
  }
</script>
<style>
</style>
